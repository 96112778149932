<template>
    <div class="MoveFile">
        <van-tabs @click="changeType" v-model="tabIndex" class="tabBox">
            <van-tab v-for="(tab,index) in tabs" :title="tab" :key="index">
            </van-tab>
        </van-tabs>
        <div class="resultBox van-hairline--top">
            <transition name="van-fade">
                <scroll v-if="!isLoading&&list.length>0" class="wrapper" ref="scroll" :pullUpLoad="pullUpLoadObj" @pullingUp="onPullingUp">
                    <ul class="docList">
                        <li v-for="(item,index) in list" class="docItem " :class="{'van-hairline--bottom':index!=list.length-1}" @click="item.isFolder?intoFolder(item.folderId,item.folderName):''" :key="index">
                            <template v-if="item.isFolder">
                                <div class="iconBox">
                                    <i class="iconFONT iconfont " :class="item.folderType==1?'icon-folder-solid':'icon-space-close'"></i>
                                </div>
                                <div class="contentBox">
                                    <p class="fileName ellipsis" v-html="item.folderName"></p>
                                    <p class="fileInfo ellipsis">
                                        {{item.modifyDate||item.createDate}}
                                    </p>
                                </div>
                            </template>
                            <template v-else>
                                <div class="iconBox">
                                    <img v-if="isImage(item)" class="iconIMG" :src="item.preViewImageUrl" alt="">
                                    <svg v-else class="iconSVG" aria-hidden="true">
                                        <use :xlink:href="getIcon(item.fileExtName)"></use>
                                    </svg>
                                </div>
                                <div class="contentBox">
                                    <p class="fileName ellipsis" v-html="`${item.fileName}.${item.fileExtName}`"></p>
                                    <p class="fileInfo ellipsis">
                                        {{Global.utils.byteCalc(item.fileSize)}}&nbsp;{{item.createRealName}}&nbsp;{{item.modifyDate||item.createDate}}
                                    </p>
                                </div>
                            </template>
                        </li>
                    </ul>
                </scroll>
            </transition>
            <transition name="van-fade">
                <nodata v-if="this.list.length<=0&&!isLoading">
                </nodata>
            </transition>
            <transition name="van-fade">
                <loading v-if="isLoading" size="30px"></loading>
            </transition>
        </div>
        <div class="btmBox van-hairline--top">
            <div @click="goBack" class="goBackBtn ellipsis" :class="{' notOperable':folderNav.length==1}">
                <!-- 返回上一级 -->
                {{$t('mx_mobile.Doc.1585194322551')}}
            </div>
            <div @click="cannotSave?'':commit()" class="saveBtn ellipsis " :class="{'notOperable':cannotSave}">
                {{commitShow}}
                <!-- <template v-if="isSaving">
                    <van-loading color="black" />{{savingShow}}
                </template>
                <template v-else>{{commitShow}}</template> -->
            </div>
        </div>
    </div>
</template>

<script>
import mixin from '../Vue/mixin.js'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'MoveFile',
    data() {
        return {
            isLoading: false,
            isSaving: false,
            tabs: [
                this.$t('mx_mobile.Doc.1585191603724'), // '我的文档'
                this.$t('mx_mobile.Doc.1585191556548') // '知识库'
            ],
            moduleCode: ['DC002', 'DC001'],
            folderNav: [],
            folderSource: [2, 1],
            tabIndex: 0,
            type: '', // move copy
            fileIds: [],
            page: {
                pageN: 0,
                pageSize: 10,
                total: 0
            },
            list: [],
            pullUpLoad: true,
            pullUpLoadThreshold: 20,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Doc.1585194359043'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Doc.1585194375114'), // '没有更多数据了'
            cancelPreReq: null
        }
    },
    mixins: [mixin],
    mounted() {
        this.pageInit()
    },
    computed: {
        pullUpLoadObj: function () {
            return this.pullUpLoad
                ? {
                    threshold: parseInt(this.pullUpLoadThreshold),
                    txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
                }
                : false
        },
        commitShow() {
            let name = this.folderNav[this.folderNav.length - 1].name
            // '移动到' '复制到'
            return this.type == 'move'
                ? this.$t('mx_mobile.Doc.1585194399448') + name
                : this.$t('mx_mobile.Doc.1585194420981') + name
        },
        savingShow() {
            // '移动中...' '复制中...'
            return this.type == 'move'
                ? this.$t('mx_mobile.Doc.1585194439883')
                : this.$t('mx_mobile.Doc.1585194518462')
        },
        isKRoot() {
            return this.folderNav.length == 1 && this.tabIndex == 1
        },
        cannotSave() {
            return this.isSaving || this.isKRoot
        }
    },
    methods: {
        initFolderNav() {
            // '我的文档'  '知识库'
            let folders = [[{ id: 2, name: this.$t('mx_mobile.Doc.1585191603724') }], [{ id: 1, name: this.$t('mx_mobile.Doc.1585191556548') }]]
            this.folderNav = Object.assign([], folders[this.tabIndex])
        },
        goBack() {
            if (this.folderNav.length == 1) {
                return false
            }
            this.folderNav.splice(this.folderNav.length - 1, 1)
            this.reGetList()
        },
        changeType(index) {
            if (this.tabIndex == index) { return }
            this.tabIndex = index
            this.initFolderNav()
            this.reGetList()
        },
        intoFolder(id, name) {
            this.folderNav.push({ id, name })
            this.reGetList()
        },
        async commit() {
            let url, successTitle, text
            if (this.type == 'move') {
                url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_changeFolder
                successTitle = this.$t('mx_mobile.Doc.1585194749930') // '移动成功'
                text = this.$t('mx_mobile.Doc.1585194439883') // '移动中...'
            } else {
                url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_copyFile
                successTitle = this.$t('mx_mobile.Doc.1585194769281') // '复制成功'
                text = this.$t('mx_mobile.Doc.1585194518462') // '复制中...'
            }

            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: text
            })
            this.isSaving = true
            await this.sleep()
            let flag = await this.moveOrCopy(url)
            this.$toast.clear()
            this.isSaving = false
            if (flag) {
                this.$toast.success(successTitle)
                /* 返回 */
                this.$router.back()
            }
        },
        async sleep(duration = 1000) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, duration)
            })
        },
        async moveOrCopy(url) {
            let flag = false

            let data = {
                targetFolderId: this.folderNav[this.folderNav.length - 1].id,
                fileIds: this.fileIds
            }
            try {
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    /* 移动成功 */
                    flag = true
                } else {
                    flag = false
                    this.$toast.fail(this.msg(res.data))
                }
            } catch (error) {
                flag = false
                this.$toast.fail(this.$t('mx_mobile.Doc.1585194816901')) // '出错了'
            }

            return flag
        },

        async reGetList() {
            this.page.pageN = 1
            this.page.total = 0
            this.isLoading = true
            let list = await this.getData()
            if (list) {
                this.list = list
                this.isLoading = false
            }
        },
        async getData() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_files
                let data = {
                    params: {
                        folderSource: this.folderSource[this.tabIndex],
                        folderId: this.folderNav[this.folderNav.length - 1].id,
                        docType: 'ALL',
                        pageN: this.page.now,
                        pageSize: this.page.size,
                        moduleCode: this.moduleCode[this.tabIndex],
                        params: 'w_80,h_80,m_fill',
                        optCode: 'otUpload'
                    },
                    cancelToken: new this.axios.CancelToken((cancel) => {
                        if (this.cancelPreReq) {
                            this.cancelPreReq()
                        }
                        this.cancelPreReq = cancel
                    })
                }
                let list = []
                let res = await this.axios.get(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.page.total = res.data.data.totalNum
                    list = res.data.data.list
                } else {
                    this.$toast.falil(res.data.msg)
                }
                return list
            } catch (error) {
                if (this.axios.isCancel(error)) {
                    console.log('is cancel req')
                    return false
                } else {
                    console.log(error)
                    return []
                }
            }
        },
        async onPullingUp() {
            if (this.list.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.list.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.list = this.list.concat(list)
                this.$nextTick(() => {
                    if (this.list.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        setTitle() {
            if (this.type == 'move') {
                this.setHeaderTitle(this.$t('mx_mobile.Doc.1585194872224')) // '文件移动'
            }
            if (this.type == 'copy') {
                this.setHeaderTitle(this.$t('mx_mobile.Doc.1585194898079')) // '文件复制'
            }
        },
        pageInit() {
            this.Global.utils.rightMenu.clearMenu()
            let { type, fileIds } = this.$route.params
            if (type && fileIds.length > 0) {
                this.type = type
                this.fileIds = fileIds
                this.initFolderNav()
                this.setTitle()
                this.reGetList()
            } else {
                this.$router.back()
            }
        }
    },
    watch: {
        $route(to, from) {
            if (to.name == 'MoveFile') {
                this.pageInit()
            }
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    components: {
        'scroll': Scroll
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
